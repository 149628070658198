<template>
    <ConfirmationForm
        type="special-deals"
        :price="price"
        :setup-price="setupPrice"
        :configuration="summary"
        :redirectParams="`?redirect=dedicated-server-mieten/confirm&query=${queryParams}`"
    />
</template>

<script setup>
import { UseSpecialDealsConfiguration } from '../functions/useSpecialDealsConfiguration';
import ConfirmationForm from './ConfirmationForm.vue';
import { getQueryParams } from '../../../utils/windowUtils';

const queryParams = getQueryParams('|');
const { price, setupPrice, summary } = UseSpecialDealsConfiguration();
</script>
