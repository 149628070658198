import _pickBy from 'lodash/pickBy';
import _mapValues from 'lodash/mapValues';

export function pickByIds(assortment, ids) {
    return _pickBy(assortment, (value, key) => ids.includes(parseInt(key)));
}

export function mapAttribute(assortment, key, postfix = '') {
    return _mapValues(assortment, (entry) => entry[key] + postfix);
}

export function mapSubnetAttribute(assortment, key, postfix = '') {
    return _mapValues(assortment, (entry) => `${entry[key] + postfix} (${entry.addresses} IPs)`);
}
