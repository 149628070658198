<template>
    <div>
        <p class="flex flex-wrap items-center justify-between text-left text-lg text-white">
            <div> {{ label }} </div> <slot name="tooltip"></slot>
        </p>
        <select
            :name="name"
            :value="modelValue"
            class="w-full rounded border border-gray-400 bg-gray-600 p-3 text-white"
            @input="$emit('update:modelValue', $event.target.value)"
        >
            <template v-if="showEmptyValue">
                <option value="">-</option>
            </template>
            <template
                v-for="(value, key) in options"
                :key="key"
            >
                <option :value="key">
                    {{ value }}
                </option>
            </template>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        modelValue: { default: null },
        label: { type: String, required: true },
        options: { type: Object, required: true },
        showEmptyValue: { type: Boolean, default: false },
    },
    emits: ['update:modelValue'],
};
</script>
