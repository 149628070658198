<template>
    <form
        @submit.prevent="send"
        data-spec-id="confirmation-form"
    >
        <div class="flex flex-col items-center rounded bg-gray-700 px-12 py-12 text-white xl:px-24">
            <div
                v-if="user"
                class="mb-11 block"
                data-spec-class="confirmation-form__logged-in-as"
            >
                {{ $t('configuratorGeneral.loggedInAs') }} {{ user }}
            </div>

            <div
                v-else
                class="lg:w-2/3"
            >
                <div
                    class="mb-3"
                    data-spec-class="confirmation-form__already-a-customer"
                >
                    {{ $t('configuratorGeneral.alreadyACustomer') }}
                    <a
                        class="underline"
                        :href="`/login${redirectParams}`"
                        data-spec-class="confirmation-form__login-link"
                    >
                        {{ $t('configuratorGeneral.loginHere') }}
                    </a>
                </div>

                <String
                    v-model="form.name"
                    :label="$t('contact.name')"
                    name="name"
                    :errors="sendingStatus.errors.name"
                    data-spec-class="confirmation-form__field"
                />
                <String
                    v-model="form.email"
                    label="Mail"
                    name="email"
                    :errors="sendingStatus.errors.email"
                    data-spec-class="confirmation-form__field"
                />
            </div>

            <p
                class="mt-6"
                v-html="$t('privacy')"
            />

            <div class="mt-11">
                <Confirm v-if="sendingStatus.busy === false && sendingStatus.success === null">
                    <span
                        v-if="isOrderable"
                        data-spec-class="confirmation-form__submit--order"
                    >
                        {{ $t('configuratorGeneral.orderButton') }}
                    </span>
                    <span
                        v-else
                        data-spec-class="confirmation-form__submit--request"
                    >
                        {{ $t('configuratorGeneral.requestButton') }}
                    </span>
                </Confirm>
                <p v-if="sendingStatus.busy">
                    {{ $t('contact.loading') }}
                </p>
                <span
                    v-if="isOrderable && sendingStatus.success"
                    class="text-green"
                    data-spec-class="confirmation-form__success--order"
                >
                    {{ $t('configuratorGeneral.orderSuccess') }}
                </span>
                <div
                    v-if="!isOrderable && sendingStatus.success"
                    class="text-green"
                    data-spec-class="confirmation-form__success--request"
                >
                    {{ sendingStatus.success }}
                </div>
            </div>

            <div class="mt-6 text-gray-300">
                <span
                    v-if="isOrderable && orderHint"
                    data-spec-class="confirmation-form__hint--order"
                >
                    {{ orderHint }}
                </span>
                <span
                    v-else
                    data-spec-class="confirmation-form__hint--request"
                >
                    {{ $t('configuratorGeneral.requestText') }}
                </span>
            </div>

            <p
                v-if="sendingStatus.error"
                class="mt-3 text-red"
                v-text="sendingStatus.error"
            />
            <p
                v-if="sendingStatus.errors.mail_address"
                class="mt-3 text-red"
                v-text="sendingStatus.errors.mail_address"
            />
        </div>
        <div class="honeypot">
            <input
                v-model="form.mail_address"
                type="text"
                :label="$t('honeypot')"
                name="mail_address"
                tabindex="-1"
                autocomplete="off"
            />
        </div>
    </form>
</template>

<script setup>
import { UseFormSubmit } from '../functions/useFormSubmit';
import String from './Form/String.vue';
import Confirm from './Configurator/Confirm.vue';

const props = defineProps({
    type: { required: true },
    price: { required: true },
    setupPrice: { required: false },
    configuration: { required: false },
    selection: { required: false },
    urlModifier: { default: '' },
    redirectParams: { default: '' },
    isOrderable: { default: false },
    orderHint: { default: '' },
});

const feedGoogleTagManager = (form) => {
    if (dataLayer && form.type === props.type && form.price) {
        dataLayer.push({
            event: `${form.type}-submitted`,
            conversionValue: form.price,
        });
    }
};

let submitData = {};
if (props.user) {
    submitData = {
        configuration: props.configuration,
        type: props.type,
        price: props.price,
        setupPrice: props.setupPrice,
        selection: props.selection,
        privacy: false,
        lang: document.documentElement.lang,
    };
} else {
    submitData = {
        configuration: props.configuration,
        type: props.type,
        price: props.price,
        setupPrice: props.setupPrice,
        selection: props.selection,
        name: '',
        email: '',
        mail_address: '',
        privacy: false,
        lang: document.documentElement.lang,
    };
}

const { form, send, sendingStatus } = UseFormSubmit(
    window.location.href + props.urlModifier,
    submitData,
    feedGoogleTagManager
);

const user = document.querySelector('meta[name="user"]').getAttribute('content') || '';
</script>
