export const rams = {
    0: { id: 0, capacity: 4, price: 1 },
    1: { id: 1, capacity: 8, price: 2 },
    2: { id: 2, capacity: 16, price: 4 },
    3: { id: 3, capacity: 32, price: 8 },
    4: { id: 4, capacity: 64, price: 16 },
    5: { id: 5, capacity: 128, price: 32 },
    7: { id: 7, capacity: 256, price: 64 },
    8: { id: 8, capacity: 512, price: 128 },
};

export const storages = {
    15: { id: 15, type: 'SSD', capacity: '960 GB', price: 4 },
    20: { id: 20, type: 'SSD', capacity: '1.92 TB', price: 11 },
    25: { id: 25, type: 'SSD', capacity: '3.84 TB', price: 22 },
    30: { id: 30, type: 'SSD', capacity: '7.68 TB', price: 44 },

    40: { id: 40, type: 'HDD', capacity: '4 TB', price: 4 },
    45: { id: 45, type: 'HDD', capacity: '8 TB', price: 8 },
    50: { id: 50, type: 'HDD', capacity: '16 TB', price: 16 },

    55: { id: 55, type: 'NVMe', capacity: '960 GB', price: 8 },
    60: { id: 60, type: 'NVMe', capacity: '1.92 TB', price: 11 },
    65: { id: 65, type: 'NVMe', capacity: '3.84 TB', price: 22 },
    70: { id: 70, type: 'NVMe', capacity: '7.68 TB', price: 44 },
};

export const permanentStorages = {
    1: { id: 1, type: 'NVMe', capacity: '1 TB', price: 0 },
    2: { id: 2, type: 'HDD', capacity: '4 TB', price: 0 },
    3: { id: 3, type: 'SSD', capacity: '480 GB', price: 0 },
    4: { id: 4, type: 'NVMe', capacity: '960 GB', price: 0 },
};

export const tags = {
    cpus: {
        id: 'cpus',
        name: 'CPU',
        theme: 'orange',
        tags: [
            { id: 'intel', name: 'Intel', theme: 'orange', tags: [] },
            { id: 'amd', name: 'AMD', theme: 'orange', tags: [] },
        ],
    },
    storages: {
        id: 'storages',
        name: 'Storage',
        theme: 'green',
        tags: [
            { id: 'hdd', name: 'HDD', theme: 'green', tags: [] },
            { id: 'ssd', name: 'SSD', theme: 'green', tags: [] },
            { id: 'nvme', name: 'NVMe', theme: 'green', tags: [] },
            { id: 'raid', name: 'HW-Raid', theme: 'green', tags: [] },
        ],
    },
    uplinks: {
        id: 'uplinks',
        name: 'Network',
        theme: 'purple',
        tags: [
            { id: '1', name: '1 GE', theme: 'purple', tags: [] },
            { id: '10', name: '10 GE', theme: 'purple', tags: [] },
        ],
    },
};

export const servers = {
    13: {
        id: 13,
        name: 'Intel Xeon E3-1230v5',
        cpu: '4 Cores, 8 Threads, 3.4 GHz',
        spec: '',
        price: 47,
        tags: [
            tags.cpus.tags.find((tag) => tag.id === 'intel'),
            tags.storages.tags.find((tag) => tag.id === 'hdd'),
            tags.storages.tags.find((tag) => tag.id === 'raid'),
            tags.uplinks.tags.find((tag) => tag.id === '1'),
        ],
        badges: [],
        fitting: {
            storages: {
                size: 0,
                ids: [],
            },
            rams: [3],
            networks: [1],
        },
        defaults: {
            ram: 3,
            storages: {},
            permanentStorages: { 2: 2 },
            network: 1,
        },
    },
    14: {
        id: 14,
        name: 'Intel Xeon E3-1230v5',
        cpu: '4 Cores, 8 Threads, 3.4 GHz',
        spec: '',
        price: 47,
        tags: [
            tags.cpus.tags.find((tag) => tag.id === 'intel'),
            tags.storages.tags.find((tag) => tag.id === 'ssd'),
            tags.storages.tags.find((tag) => tag.id === 'raid'),
            tags.uplinks.tags.find((tag) => tag.id === '1'),
        ],
        badges: [],
        fitting: {
            storages: {
                size: 0,
                ids: [],
            },
            rams: [3],
            networks: [1],
        },
        defaults: {
            ram: 3,
            storages: {},
            permanentStorages: { 3: 2 },
            network: 1,
        },
    },
    15: {
        id: 15,
        name: 'Intel Silver 4110',
        cpu: '8 Cores, 16 Threads, 2.1 GHz',
        spec: '',
        price: 53,
        tags: [
            tags.cpus.tags.find((tag) => tag.id === 'intel'),
            tags.storages.tags.find((tag) => tag.id === 'hdd'),
            tags.storages.tags.find((tag) => tag.id === 'ssd'),
            tags.storages.tags.find((tag) => tag.id === 'raid'),
            tags.uplinks.tags.find((tag) => tag.id === '1'),
        ],
        badges: [],
        fitting: {
            storages: {
                size: 0,
                ids: [],
            },
            rams: [4],
            networks: [1],
        },
        defaults: {
            ram: 4,
            storages: {},
            permanentStorages: { 2: 2, 3: 2 },
            network: 1,
        },
    },
    16: {
        id: 16,
        name: 'Intel Xeon E-2246G',
        cpu: '6 Cores, 12 Threads, 3.6 GHz',
        spec: '',
        price: 97,
        tags: [
            tags.cpus.tags.find((tag) => tag.id === 'intel'),
            tags.storages.tags.find((tag) => tag.id === 'nvme'),
            tags.storages.tags.find((tag) => tag.id === 'hdd'),
            tags.uplinks.tags.find((tag) => tag.id === '1'),
        ],
        badges: [],
        fitting: {
            storages: {
                size: 0,
                ids: [],
            },
            rams: [4],
            networks: [1],
        },
        defaults: {
            ram: 4,
            storages: {},
            permanentStorages: { 1: 2, 2: 2 },
            network: 1,
        },
    },
    17: {
        id: 17,
        name: 'Intel Xeon E-2246G',
        cpu: '6 Cores, 12 Threads, 3.6 GHz',
        spec: '',
        price: 102,
        tags: [
            tags.cpus.tags.find((tag) => tag.id === 'intel'),
            tags.storages.tags.find((tag) => tag.id === 'nvme'),
            tags.storages.tags.find((tag) => tag.id === 'hdd'),
            tags.uplinks.tags.find((tag) => tag.id === '10'),
        ],
        badges: [],
        fitting: {
            storages: {
                size: 0,
                ids: [],
            },
            rams: [4],
            networks: [2],
        },
        defaults: {
            ram: 4,
            storages: {},
            permanentStorages: { 1: 2, 2: 2 },
            network: 2,
        },
    },
    18: {
        id: 18,
        name: 'Intel Xeon E5-2620 v3',
        cpu: '6 Cores, 12 Threads, 2.4 GHz',
        spec: '',
        price: 175,
        tags: [
            tags.cpus.tags.find((tag) => tag.id === 'intel'),
            tags.storages.tags.find((tag) => tag.id === 'hdd'),
            tags.storages.tags.find((tag) => tag.id === 'raid'),
            tags.uplinks.tags.find((tag) => tag.id === '1'),
        ],
        badges: [],
        fitting: {
            storages: {
                size: 0,
                ids: [],
            },
            rams: [4],
            networks: [1],
        },
        defaults: {
            ram: 4,
            storages: {},
            permanentStorages: { 2: 10 },
            network: 1,
        },
    },
    19: {
        id: 19,
        name: 'AMD EPYC 7502P',
        cpu: '32 Cores, 64 Threads, 2.5 GHz',
        spec: '',
        price: 324,
        tags: [
            tags.cpus.tags.find((tag) => tag.id === 'amd'),
            tags.storages.tags.find((tag) => tag.id === 'nvme'),
            tags.uplinks.tags.find((tag) => tag.id === '10'),
        ],
        badges: [],
        fitting: {
            storages: {
                size: 0,
                ids: [],
            },
            rams: [7],
            networks: [2],
        },
        defaults: {
            ram: 7,
            storages: {},
            permanentStorages: { 4: 4 },
            network: 2,
        },
    },
};
