<template>
    <div
        class="w-full rounded bg-gray-700 p-12 text-white"
        data-spec-class="summary"
    >
        <ColumnTitle class="mb-6">
            {{ $t('configuratorGeneral.summaryTitle') }}
        </ColumnTitle>

        <BaseButton
            v-if="!areMobileDetailsVisible"
            @click="areMobileDetailsVisible = true"
            class="lg:hidden"
        >
            {{ $t('configuratorGeneral.showSummary') }}
        </BaseButton>
        <div
            :class="[
                'lg:block',
                {
                    hidden: !areMobileDetailsVisible,
                },
            ]"
        >
            <slot>
                <ul class="divide-y divide-solid divide-gray-500 text-left text-lg">
                    <li
                        v-for="(summaryPosition, i) in summaryPositions"
                        :key="i"
                        data-spec-class="summary__position"
                    >
                        <div
                            class="flex justify-between py-1"
                            v-for="position in positionsBy(summaryPosition)"
                        >
                            <div>
                                <div class="flex items-center">
                                    {{ position.name }}

                                    <component
                                        v-if="position.component"
                                        :is="position.component"
                                    >
                                    </component>
                                </div>

                                <div class="-mt-1 text-base text-gray-300">
                                    {{ position.description }}
                                </div>
                            </div>

                            <div>{{ $n(position.monthlyPrice || 0, 'currency') }}</div>
                        </div>
                    </li>
                </ul>
            </slot>
        </div>

        <div class="mt-6 text-center lg:text-right">
            <div>
                <div>
                    <span class="text-2xl text-blue-700"> {{ $n(monthlyPrice, 'currency') }} </span>
                    <div class="text-base text-gray-300">
                        {{ $t('configuratorGeneral.perMonth') }}
                    </div>
                </div>

                <p
                    v-if="setupPrice"
                    class="mt-3 flex justify-center text-gray-300 lg:justify-end"
                >
                    {{
                        $t('configuratorGeneral.setupAdditional', {
                            setupFee: $n(setupPrice, 'currency'),
                        })
                    }}
                    <BaseTooltipHelp class="ml-1 h-5 w-5">
                        <div
                            v-for="(position, i) in positionsWithSetupPrices"
                            :key="i"
                        >
                            {{ position.name }}: {{ $n(position.setupPrice, 'currency') }}
                        </div>
                    </BaseTooltipHelp>
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import ColumnTitle from './../Configurator/ColumnTitle.vue';

const props = defineProps({
    summaryPositions: Array,
});

const areMobileDetailsVisible = ref(false);

const monthlyPrice = computed(() => {
    return props.summaryPositions.flat().reduce((acc, position) => {
        return acc + (position.monthlyPrice || 0);
    }, 0);
});

const setupPrice = computed(() => {
    return props.summaryPositions.flat().reduce((acc, position) => {
        return acc + (position.setupPrice || 0);
    }, 0);
});

const positionsWithSetupPrices = computed(() => {
    return props.summaryPositions.flat().filter((position) => position.setupPrice > 0);
});

const positionsBy = (summaryPosition) => {
    if (summaryPosition instanceof Array) {
        return summaryPosition;
    }

    return [summaryPosition];
};
</script>
