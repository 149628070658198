<template>
    <Configurator
        url="/dedicated-server-mieten/confirm"
        v-if="selectedServer"
        data-spec-class="special_deals_configurator"
    >
        <img
            src="/img/icons/23m/dedicated-server.svg"
            alt="Dedicated Server"
            class="mx-auto h-24 w-36"
        />
        <Columns>
            <Column>
                <ColumnTitle>Server</ColumnTitle>
                <div class="flex">
                    <SpecialDealsCard
                        :server="selectedServer"
                        class="w-full"
                    />
                </div>
            </Column>

            <Column>
                <ColumnTitle>{{ $t('configuratorGeneral.network') }}</ColumnTitle>
                <div class="space-y-3">
                    <Dropdown
                        v-model="state.selected.volume"
                        name="volume"
                        :label="$t('configuratorGeneral.includedBandwidth')"
                        :options="volumeList"
                    >
                        <template #tooltip>
                            <VolumeTooltip />
                        </template>
                    </Dropdown>
                    <Hint>{{ $t('configuratorGeneral.eachMbit') }}</Hint>
                </div>
                <IpConfigurationFieldset
                    :assortment="state.assortment"
                    :fields="state.selected"
                    :selection="state.selected"
                />
            </Column>
            <Column>
                <ColumnTitle>{{ $t('configuratorGeneral.price') }}</ColumnTitle>
                <PriceBanner
                    :price="price"
                    :setup-price="setupPrice"
                />
                <Price
                    :price="price"
                    :setup_price="setupPrice"
                />
                <DedicatedAvailability :is-default-config="true" />
                <div>
                    <Hint>{{ $t('configuratorDedicated.contractTerm') }}</Hint>
                    <Hint>{{ $t('configuratorDedicated.noticePeriod') }}</Hint>
                </div>
                <Confirm>{{ $t('configuratorGeneral.continue') }}</Confirm>
            </Column>
        </Columns>
    </Configurator>
    <div v-else>
        <div
            class="mb-12 flex w-full flex-col items-center rounded bg-gray-700 p-6"
            data-spec-class="special_deals_configurator__no-server"
        >
            <img
                src="/img/icons/23m/dedicated-server.svg"
                alt="Dedicated Server"
                class="mx-auto mb-6 h-24 w-36"
            />
            <span class="text-xl text-gray-300">
                {{ $t('configuratorSpecialDeals.noServerSelectedTitle') }}
            </span>
            <span class="text-lg text-gray-400">
                {{ $t('configuratorSpecialDeals.noServerSelectedDescription') }}
            </span>

            <BaseButton
                class="mt-3"
                href="/dedicated-server-mieten"
                theme="cta"
            >
                {{ $t('configuratorSpecialDeals.noServerSelectedAction') }}
            </BaseButton>
        </div>
    </div>
</template>

<script setup>
import _pickBy from 'lodash/pickBy';

import { UseSpecialDealsConfiguration } from '../functions/useSpecialDealsConfiguration';
import Configurator from './Configurator/Configurator.vue';
import Columns from './Configurator/Columns.vue';
import Column from './Configurator/Column.vue';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Dropdown from './Configurator/Dropdown.vue';
import Price from './Configurator/Price.vue';
import PriceBanner from './Configurator/PriceBanner.vue';
import Confirm from './Configurator/Confirm.vue';
import Hint from './Configurator/Hint.vue';
import { mapAttribute, mapSubnetAttribute } from '../assortments/AssortmentManager';
import DedicatedAvailability from './DedicatedConfigurator/DedicatedAvailability.vue';
import SpecialDealsCard from './SpecialDealsCard.vue';
import IpConfigurationFieldset from './IpConfigurationFieldset.vue';
import { computed } from 'vue';
import { url } from '@vuelidate/validators';
import VolumeTooltip from './VolumeTooltip.vue';

const { state, price, setupPrice, selectedServer, selectedNetwork, loadConfigFromUrlParams } =
    UseSpecialDealsConfiguration();

const urlParams = loadConfigFromUrlParams();

const volumeList = computed(() => {
    if (!urlParams.server) {
        return [];
    }
    const allowedVolumes = _pickBy(
        state.assortment.volumes,
        (item) => item.volume <= selectedNetwork.value.maxVolume
    );
    return mapAttribute(allowedVolumes, 'volume', ' Mbit/s');
});

const ipv4SubnetList = mapSubnetAttribute(state.assortment.ipv4Subnets, 'name', ' Subnet');
const ipv6SubnetList = mapSubnetAttribute(state.assortment.ipv6Subnets, 'name', ' Subnet');
</script>
