<template>
    <ConfirmationForm
        type="cloud"
        :price="price"
        :setup-price="setupPrice"
        :configuration="summary"
        :redirectParams="`?redirect=cloud-server/confirm&query=${queryParams}`"
    />
</template>

<script setup>
import ConfirmationForm from './ConfirmationForm.vue';
import { UseCloudConfiguration } from '../functions/useCloudConfiguration';
import { getQueryParams } from '../../../utils/windowUtils';

const queryParams = getQueryParams('|');
const { price, setupPrice, summary } = UseCloudConfiguration();
</script>
