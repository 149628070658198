<template>
    <Summary
        :summary-positions="summaryPositions"
        data-spec-id="special-deals-summary"
    >
    </Summary>
</template>

<script setup>
import { computed, h } from 'vue';
import { UseSpecialDealsConfiguration } from '../functions/useSpecialDealsConfiguration';
import Summary from './Configurator/Summary.vue';
import { useI18n } from 'vue-i18n';
import VolumeTooltip from './VolumeTooltip.vue';

const { t } = useI18n();

const summaryPositions = computed(() => {
    const summaries = {};
    if (selectedServer.value) {
        summaries.server = [
            {
                name: selectedServer.value.name,
                description: selectedServer.value.cpu,
                monthlyPrice: selectedServer.value.price,
                setupPrice: null,
            },
        ];

        summaries.ram = {
            name: getRam(selectedServer.value.defaults.ram).capacity + ' GB RAM',
            description: null,
            monthlyPrice: null,
            setupPrice: null,
        };

        summaries.permanentStorages = getPermanentStorages(
            selectedServer.value.defaults.permanentStorages
        ).map((storage) => {
            return {
                name: storage.quantity + ' x ' + storage.storage.capacity,
                description: null,
                monthlyPrice: storage.quantity * storage.storage.price,
                setupPrice: null,
            };
        });

        summaries.network = {
            name: selectedNetwork.value.name,
            description: null,
            monthlyPrice: null,
            setupPrice: null,
        };
    }

    if (selectedVolume.value) {
        summaries.volume = {
            name: selectedVolume.value.volume + ' Mbit/s',
            description: null,
            monthlyPrice: selectedVolume.value.price,
            setupPrice: null,
            component: h(VolumeTooltip, { class: 'ml-1 mb-1' }),
        };
    }

    if (state.selected.ipv4Addresses) {
        summaries.ipv4Addresses = {
            name:
                state.selected.ipv4Addresses +
                ' ' +
                t('configuratorGeneral.ipv4Addresses', {
                    count: state.selected.ipv4Addresses,
                }),
            description:
                numberOfPayedIpv4Addresses.value > 1
                    ? t('configuratorGeneral.ipv4AddressesIncluded', {
                          count: state.assortment.ipv4Address.free,
                      })
                    : null,
            monthlyPrice: state.assortment.ipv4Address.price * numberOfPayedIpv4Addresses.value,
            setupPrice: state.assortment.ipv4Address.setupPrice * numberOfPayedIpv4Addresses.value,
        };
    }

    if (selectedIpv4Subnet.value) {
        summaries.ipv4Subnet = {
            name: selectedIpv4Subnet.value.name + ' ' + t('configuratorGeneral.ipv4Subnet'),
            description: null,
            monthlyPrice: selectedIpv4Subnet.value.price,
            setupPrice: selectedIpv4Subnet.value.setup,
        };
    }
    if (selectedIpv6Subnet.value) {
        summaries.ipv6Subnet = {
            name: selectedIpv6Subnet.value.name + ' ' + t('configuratorGeneral.ipv6Subnet'),
            description: null,
            monthlyPrice: selectedIpv6Subnet.value.price,
            setupPrice: selectedIpv6Subnet.value.setup,
        };
    }

    return Object.values(summaries);
});

const {
    state,
    selectedServer,
    selectedNetwork,
    selectedVolume,
    selectedIpv4Subnet,
    selectedIpv6Subnet,
    numberOfPayedIpv4Addresses,
    getRam,
    getPermanentStorages,
    loadConfigFromUrlParams,
} = UseSpecialDealsConfiguration();

loadConfigFromUrlParams();
</script>
