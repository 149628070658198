<template>
    <Summary
        data-spec-id="dedicated-summary"
        :summary-positions="summaryPositions"
    >
    </Summary>
</template>

<script setup>
import { computed, h } from 'vue';
import { useAssortmentDedicated } from '../composables/assortmentDedicated';
import { useAssortmentSummary } from '../composables/assortmentSummary';
import Summary from './Configurator/Summary.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    assortmentContent: String,
});
const {
    assortment,
    selection,
    numberOfPayedIpv4Addresses,
    monthlyPrices,
    setupPrices,
    decorateCpuSpecs,
} = useAssortmentDedicated(props.assortmentContent);

const { uplinkSummary, volumeSummary, ipv4AddressesSummary, ipv4SubnetSummary, ipv6SubnetSummary } =
    useAssortmentSummary(
        assortment,
        selection,
        monthlyPrices,
        setupPrices,
        numberOfPayedIpv4Addresses
    );

const summaryPositions = computed(() => {
    const summaries = {};
    if (selection.value.device) {
        summaries.device = {
            name: selection.value.device.name,
            description: `${selection.value.device.cpu.name} (${decorateCpuSpecs(
                selection.value.device.cpu
            )})`,
            monthlyPrice: monthlyPrices.value.device,
            setupPrice: setupPrices.value.device,
        };
    }

    if (selection.value.ram) {
        summaries.ram = {
            name: selection.value.ram.capacity + ' GB RAM',
            description: null,
            monthlyPrice: monthlyPrices.value.ram,
            setupPrice: setupPrices.value.ram,
        };
    }

    if (selection.value.storages.length) {
        summaries.storages = selection.value.storages.map((storage) => {
            return {
                name: storage.quantity + ' x ' + storage.storage.name,
                description: null,
                monthlyPrice: storage.quantity * storage.storage.price.monthly_price,
                setupPrice: storage.quantity * storage.storage.price.setupPrice,
            };
        });
    }

    summaries.uplink = uplinkSummary.value;
    summaries.volume = volumeSummary.value;
    summaries.ipv4Addresses = ipv4AddressesSummary.value;
    summaries.ipv4Subnet = ipv4SubnetSummary.value;
    summaries.ipv6Subnet = ipv6SubnetSummary.value;

    return Object.values(summaries).filter((item) => item !== null);
});
</script>
