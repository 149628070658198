<template>
    <div>
        <a
            class="hidden grid-cols-6 gap-6 lg:grid"
            :class="classObject"
            :href="href"
        >
            <div class="whitespace-nowrap">
                <div class="text-white">{{ server.name }}</div>
                <div
                    v-if="server.cpu"
                    class="text-xs text-blue-600"
                >
                    {{ server.cpu }}
                </div>
                <input
                    type="hidden"
                    name="server"
                    :value="server.id"
                />
            </div>
            <div
                class="text-white"
                v-if="server.spec"
            >
                {{ server.spec }}
            </div>
            <div class="place-self-center text-white">{{ ram.capacity }} GB</div>
            <div
                class="place-self-center text-white"
                style="min-width: 8rem"
            >
                <div v-for="text in storagesSummary(permanentStorages).split(',')">
                    {{ text }}
                </div>
            </div>
            <div class="place-self-center text-white">{{ network.name }}</div>
            <div class="place-self-center">
                <div class="text-xl text-blue-700">
                    {{ $n(server.price, 'currency') }}
                </div>
                <div class="text-right text-xs text-gray-300">
                    {{ $t('configuratorGeneral.perMonth') }}
                </div>
            </div>
            <div class="place-self-center">
                <BaseButton :theme="Theme.Cta">
                    {{ $t('configuratorGeneral.continue') }}
                </BaseButton>
            </div>
        </a>

        <SpecialDealsCard
            :server="server"
            :href="href"
            class="lg:hidden"
        >
            <template #aside>
                <div class="text-right">
                    <div class="text-xl text-blue-700">
                        {{ $n(server.price, 'currency') }}
                    </div>
                    <div class="text-xs text-gray-300">
                        {{ $t('configuratorGeneral.perMonth') }}
                    </div>
                    <BaseButton
                        :theme="Theme.Cta"
                        class="mt-6"
                    >
                        {{ $t('configuratorGeneral.continue') }}
                    </BaseButton>
                </div>
            </template>
        </SpecialDealsCard>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { UseSpecialDealsConfiguration } from '../functions/useSpecialDealsConfiguration';
import SpecialDealsCard from './SpecialDealsCard.vue';
import { Theme } from '../../../types';

const props = defineProps({
    server: {
        type: Object,
        required: true,
    },
});

const { storagesSummary, getRam, getNetwork, getPermanentStorages } =
    UseSpecialDealsConfiguration();
const ram = computed(() => getRam(props.server.defaults.ram));
const permanentStorages = computed(() =>
    getPermanentStorages(props.server.defaults.permanentStorages)
);
const network = computed(() => getNetwork(props.server.defaults.network));

const classObject = computed(() => ({
    'rounded border border-gray-500 bg-gray-600 p-3 text-left ring-offset-gray-600': true,
    'cursor-pointer transition duration-300 hover:outline-none hover:ring-2 hover:ring-blue-700 hover:ring-offset-2': true,
}));

const href = computed(() => {
    return `/dedicated-server-mieten/configuration?server=${props.server.id}`;
});
</script>
