<template>
    <div
        class="flex-1 text-center"
        data-spec-class="configurator-confirm"
    >
        <button
            class="rounded-full bg-purple-700 px-6 py-2 text-white"
            v-bind="$attrs"
        >
            <slot />
        </button>
    </div>
</template>
