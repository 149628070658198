<template>
    <div>
        <input
            v-model="isActive"
            type="checkbox"
            :name="tag.name"
            class="hidden"
        />

        <button
            :class="classObject"
            @click="
                () =>
                    $emit(
                        'update:modelValue',
                        isActive
                            ? modelValue.filter((v) => v !== tag.name)
                            : [...modelValue, tag.name]
                    )
            "
        >
            <slot />
        </button>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

defineEmits(['update:modelValue']);

const props = defineProps({
    tag: { type: Object, default: 'button' },
    modelValue: { default: [] },
});

const isActive = computed(() => props.modelValue.includes(props.tag.name));

const classObject = computed(() => ({
    'rounded-full border border-gray-500 px-2.5 py-1.5 text-center text-xs font-sans transition': true,
    'bg-gray-600 text-white': !isActive.value,
    'bg-green text-black': isActive.value && props.tag.theme === 'green',
    'bg-red text-white': isActive.value && props.tag.theme === 'red',
    'bg-orange-700 text-white': isActive.value && props.tag.theme === 'orange',
    'bg-orange-400 text-black': isActive.value && props.tag.theme === 'yellow',
    'bg-purple-700 text-white': isActive.value && props.tag.theme === 'purple',
    'bg-blue-700 text-white': isActive.value && props.tag.theme === 'blue',
    'bg-gray-200 text-black': isActive.value && props.tag.theme === 'gray',
    'bg-purple-400 text-white': isActive.value && props.tag.theme === 'indigo',
}));

const onClick = () => {
    $emit(
        'update:modelValue',
        isActive ? modelValue.filter((v) => v !== tag.name) : [...modelValue, tag.name]
    );
};
</script>
