<template>
    <ConfigCard
        :badges="server.badges"
        :href="href"
        class="w-full"
        data-spec-class="special-deals-card"
        :data-spec-id="`special-deals-card--${server.id}`"
    >
        <div class="text-lg text-white">{{ server.name }}</div>
        <div
            v-if="server.cpu"
            class="text-xs text-blue-600"
        >
            {{ server.cpu }}
        </div>

        <div class="text-white">
            <div v-if="server.spec">{{ server.spec }}</div>
            <div>{{ ram.capacity }} GB RAM</div>
            <div>{{ storagesSummary(permanentStorages) }}</div>
            <div>{{ network.name }}</div>
        </div>

        <input
            type="hidden"
            name="server"
            :value="server.id"
        />
        <template #aside>
            <slot name="aside"> </slot>
        </template>
    </ConfigCard>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import ConfigCard from './Configurator/ConfigCard.vue';
import { UseSpecialDealsConfiguration } from '../functions/useSpecialDealsConfiguration';

const props = defineProps({
    server: {
        type: Object,
        required: true,
    },
    href: {
        type: String,
        default: undefined,
    },
});

const { getRam, getPermanentStorages, getNetwork, storagesSummary } =
    UseSpecialDealsConfiguration();

const ram = computed(() => getRam(props.server.defaults.ram));
const permanentStorages = computed(() =>
    getPermanentStorages(props.server.defaults.permanentStorages)
);
const network = computed(() => getNetwork(props.server.defaults.network));
</script>
