/* eslint-disable import/prefer-default-export */
import { ref, computed } from 'vue';
import { chunkArray } from '../utils/arrayUtils';

export function usePagination(records, perPage = 10) {
    const chunks = computed(() => chunkArray(records.value, perPage));
    const currentPage = ref(1);
    const currentChunk = computed(() => chunks.value[currentPage.value - 1]);
    const lastPage = computed(() => Math.ceil(records.value.length / perPage));

    const meta = computed(() => {
        if (!currentChunk.value) {
            return {
                from: 0,
                to: 0,
                total: 0,
                current_page: 0,
                last_page: 0,
            };
        }

        return {
            from: (currentPage.value - 1) * perPage + 1,
            to:
                currentChunk.value.length === perPage
                    ? currentPage.value * perPage
                    : (currentPage.value - 1) * perPage + currentChunk.value.length,
            total: records.value.length,
            current_page: currentPage.value,
            last_page: lastPage.value,
        };
    });

    const onPageUpdate = (page) => {
        currentPage.value = page;
    };

    return {
        meta,
        currentChunk,
        onPageUpdate,
    };
}
