import _max from 'lodash/max';
import _sum from 'lodash/sum';

import { reactive, computed } from 'vue';
import { permanentStorages, rams, servers, tags } from '../assortments/SpecialDealsAssortment';
import {
    networks,
    volumes,
    ipv4Address,
    ipv4Subnets,
    ipv6Subnets,
} from '../assortments/NetworkAssortment';
import { getAllUrlParams } from '../../../utils/windowUtils';

const state = reactive({
    selected: {
        server: null,
        ram: null,
        permanentStorages: {},
        network: 0,
        volume: 1,
        ipv4Addresses: 1,
        ipv4Subnet: null,
        ipv6Subnet: null,
    },
    assortment: {
        servers: servers,
        rams: rams,
        permanentStorages: permanentStorages,
        networks: networks(),
        volumes: volumes(),
        ipv4Address: ipv4Address(),
        ipv4Subnets: ipv4Subnets(),
        ipv6Subnets: ipv6Subnets(),
    },
});

const selectedServer = computed(() => state.assortment.servers[state.selected.server]);

const selectedNetwork = computed(
    () => state.assortment.networks[selectedServer.value.defaults.network]
);

const selectedVolume = computed(() => state.assortment.volumes[state.selected.volume]);

const selectedIpv4Subnet = computed(() => state.assortment.ipv4Subnets[state.selected.ipv4Subnet]);

const selectedIpv6Subnet = computed(() => state.assortment.ipv6Subnets[state.selected.ipv6Subnet]);

const numberOfPayedIpv4Addresses = computed(() =>
    Math.max(...[0, state.selected.ipv4Addresses - state.assortment.ipv4Address.free])
);

const price = computed(() => {
    const serverPrice = selectedServer.value ? selectedServer.value.price : 0;
    const volumePrice = selectedVolume.value ? selectedVolume.value.price : 0;
    const ipv4AddressPrice = numberOfPayedIpv4Addresses.value * state.assortment.ipv4Address.price;
    const ipv4SubnetPrice = selectedIpv4Subnet.value ? selectedIpv4Subnet.value.price : 0;
    const ipv6SubnetPrice = selectedIpv6Subnet.value ? selectedIpv6Subnet.value.price : 0;

    return serverPrice + volumePrice + ipv4AddressPrice + ipv4SubnetPrice + ipv6SubnetPrice;
});

const setupPrice = computed(() => {
    const ipv4AddressPrice =
        numberOfPayedIpv4Addresses.value * state.assortment.ipv4Address.setupPrice;
    const ipv4SubnetPrice = selectedIpv4Subnet.value ? selectedIpv4Subnet.value.setup : 0;
    const ipv6SubnetPrice = selectedIpv6Subnet.value ? selectedIpv6Subnet.value.setup : 0;

    return _sum([ipv4AddressPrice, ipv4SubnetPrice, ipv6SubnetPrice]);
});

const loadConfigFromUrlParams = () => {
    const urlParams = getAllUrlParams();
    if (urlParams.server) {
        state.selected.server = parseInt(urlParams.server);
    }
    if (urlParams.volume) {
        state.selected.volume = parseInt(urlParams.volume);
    }
    if (urlParams.ipv6) {
        if (urlParams.ipv6 === 'on') {
            state.selected.ipv6 = true;
        }
    }
    if (urlParams.ipv4Addresses) {
        state.selected.ipv4Addresses = parseInt(urlParams.ipv4Addresses);
    }
    if (urlParams.ipv4Subnet) {
        state.selected.ipv4Subnet = parseInt(urlParams.ipv4Subnet);
    }
    if (urlParams.ipv6Subnet) {
        state.selected.ipv6Subnet = parseInt(urlParams.ipv6Subnet);
    }

    return urlParams;
};

const summary = computed(() => {
    const serverText = `Server: ${selectedServer.value.name}`;
    const cpuText = `- ${selectedServer.value.cpu}`;
    const ramText = `- ${getRam(selectedServer.value.defaults.ram).capacity} GB RAM`;
    const permanentStorages = getPermanentStorages(selectedServer.value.defaults.permanentStorages);
    const storageText = `- ${storagesSummary(permanentStorages)}`;
    const networkText = `- ${selectedNetwork.value.name} GE Uplink`;
    const serverSummary = [serverText, cpuText, ramText, storageText, networkText].join('\n');

    const networkSummary = `Network: ${selectedVolume.value.volume} Mbit/s included`;
    const ipSummaries = [];
    if (state.selected.ipv4Addresses > 0) {
        ipSummaries.push(`${state.selected.ipv4Addresses} IPv4-Adressen`);
    }
    if (selectedIpv4Subnet.value) {
        ipSummaries.push(`${selectedIpv4Subnet.value.name}er IPv4-Netz`);
    }
    if (selectedIpv6Subnet.value) {
        ipSummaries.push(`${selectedIpv6Subnet.value.name}er IPv6-Netz`);
    }
    const ipSummary = `IP: ${ipSummaries.join(', ')}`;

    return [serverSummary, networkSummary, ipSummary].join('\n');
});

const getRam = (id) => {
    return state.assortment.rams[id] || null;
};

const getPermanentStorage = (id) => {
    return state.assortment.permanentStorages[id] || null;
};

const getPermanentStorages = (permanentStorages) => {
    return Object.entries(permanentStorages).map(([key, value]) => {
        return {
            quantity: value,
            storage: getPermanentStorage(key),
        };
    });
};

const getNetwork = (id) => {
    return state.assortment.networks[id] || null;
};

const storagesSummary = (storages) => {
    return storages
        .map(
            (storage) => `${storage.quantity} x ${storage.storage.capacity} ${storage.storage.type}`
        )
        .join(', ');
};

const badges = computed(() => {
    let badges = [];
    const servers = Object.values(state.assortment.servers);
    for (let i = 0; i < servers.length; i++) {
        const server = servers[i];
        badges.push(server.badges);
    }
    const uniqueBadges = badges
        .flat()
        .filter((badge, index, self) => index === self.findIndex((t) => t.text === badge.text));
    return uniqueBadges;
});

export function UseSpecialDealsConfiguration() {
    return {
        state,
        price,
        setupPrice,
        selectedServer,
        selectedNetwork,
        selectedVolume,
        selectedIpv4Subnet,
        selectedIpv6Subnet,
        summary,
        badges,
        tags,
        numberOfPayedIpv4Addresses,
        loadConfigFromUrlParams,
        getRam,
        getPermanentStorages,
        getNetwork,
        storagesSummary,
    };
}
