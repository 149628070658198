<template>
    <BannerFixed data-spec-class="price-banner">
        <div
            class="flex h-full w-full items-center border-t border-gray-500 bg-gray-700 p-6 text-white"
        >
            <div class="mx-auto flex flex-col">
                <div class="flex text-left text-blue-700">
                    <div class="text-2xl">
                        {{ $n(price, 'currency') }}
                    </div>
                    <div class="ml-3 text-sm text-gray-400">
                        {{ $t('configuratorGeneral.perMonth') }}
                    </div>
                </div>

                <div
                    v-if="setupPrice"
                    class="text-left text-sm text-gray-400"
                >
                    <div>
                        {{
                            $t('configuratorGeneral.setupAdditional', {
                                setupFee: $n(setupPrice, 'currency'),
                            })
                        }}
                    </div>
                </div>
            </div>
        </div>
    </BannerFixed>
</template>

<script>
import BannerFixed from './BannerFixed.vue';

export default {
    components: {
        BannerFixed,
    },
    props: {
        price: { type: Number, required: true },
        setupPrice: { type: Number, default: null },
    },
};
</script>
