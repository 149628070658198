<template>
    <Summary
        :summary-positions="summaryPositions"
        data-spec-id="colocation-summary"
    />
</template>

<script setup>
import { computed, h } from 'vue';
import { useAssortmentColocation } from '../composables/assortmentColocation';
import { useAssortmentSummary } from '../composables/assortmentSummary';
import Summary from './Configurator/Summary.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    assortmentContent: String,
});
const { assortment, selection, powerMin, numberOfPayedIpv4Addresses, monthlyPrices, setupPrices } =
    useAssortmentColocation(props.assortmentContent);

const { uplinkSummary, volumeSummary, ipv4AddressesSummary, ipv4SubnetSummary, ipv6SubnetSummary } =
    useAssortmentSummary(
        assortment,
        selection,
        monthlyPrices,
        setupPrices,
        numberOfPayedIpv4Addresses
    );

const summaryPositions = computed(() => {
    const summaries = {};

    if (selection.value.rack) {
        summaries.rack = {
            id: selection.value.rack.id,
            name: selection.value.rack.max_quantity
                ? `${selection.value.rackQuantity} ${t('configuratorColocation.unit')}`
                : `${selection.value.rack.name}`,
            description: null,
            monthlyPrice: monthlyPrices.value.rack,
            setupPrice: setupPrices.value.rack,
        };
    }

    if (selection.value.feed) {
        summaries.feed = {
            name: selection.value.feed.name,
            description: null,
            monthlyPrice: monthlyPrices.value.feed,
            setupPrice: setupPrices.value.feed,
        };
    }

    if (selection.value.power) {
        summaries.power = {
            name: selection.value.power + ' W',
            description:
                selection.value.power > powerMin.value
                    ? t('configuratorColocation.electricalPowerIncluded', {
                          count: powerMin.value,
                      })
                    : null,
            monthlyPrice: monthlyPrices.value.power,
            setupPrice: setupPrices.value.power,
        };
    }

    summaries.uplink = uplinkSummary.value;
    summaries.volume = volumeSummary.value;
    summaries.ipv4Addresses = ipv4AddressesSummary.value;
    summaries.ipv4Subnet = ipv4SubnetSummary.value;
    summaries.ipv6Subnet = ipv6SubnetSummary.value;

    return Object.values(summaries).filter((item) => item !== null);
});
</script>
