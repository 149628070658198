<template>
    <Configurator url="/dedicated-server/confirm">
        <img
            src="/img/icons/23m/dedicated-server.svg"
            alt="Dedicated Server"
            class="mx-auto h-24 w-36"
        />
        <Columns>
            <Column>
                <ColumnTitle>{{ $t('configuratorDedicated.base') }}</ColumnTitle>
                <Radios>
                    <RadioBox
                        v-for="(device, i) in assortment.devices"
                        :key="i"
                        v-model.number="fields.deviceId"
                        name="device"
                        :value="device.id"
                        :title="device.name"
                        :description="device.cpu.name"
                        :properties="decorateCpuSpecs(device.cpu)"
                        :badges="device.badges"
                    />
                </Radios>
            </Column>

            <Column>
                <ColumnTitle>RAM</ColumnTitle>
                <Radios>
                    <template
                        v-for="(ram, i) in selection.device.components.ram[0].options"
                        :key="i"
                    >
                        <Radio
                            v-model.number="fields.ramCapacity"
                            name="ram"
                            :value="ram.capacity"
                            :label="ram.capacity + ' GB'"
                        />
                    </template>
                </Radios>
            </Column>

            <Column>
                <ColumnTitle>Storage</ColumnTitle>
                <div class="flex flex-col space-y-6">
                    <RemainingSlots
                        v-if="selection.device?.components.storage[0].size"
                        :amount="freeStorageSlots"
                    />
                    <div
                        v-for="(storages, storageType) in storagesByType"
                        :key="storageType"
                    >
                        <CategoryTitle>{{ storageType }}</CategoryTitle>
                        <div class="ml-6 space-y-3">
                            <template
                                v-for="(storage, i) in storages"
                                :key="i"
                            >
                                <CheckboxQuantity
                                    v-model="fields.storageIds[storage.id]"
                                    :name="'storages[' + storage.id + ']'"
                                    :min="0"
                                    :max="
                                        parseInt(fields.storageIds[storage.id] || 0) +
                                        freeStorageSlots
                                    "
                                    @increase="increaseStorage(storage.id)"
                                    @decrease="decreaseStorage(storage.id)"
                                >
                                    {{ removeSubstring(storage.name, storageType) }}
                                </CheckboxQuantity>
                            </template>
                        </div>
                    </div>
                </div>
            </Column>

            <Column>
                <NetworkConfigurationFieldset
                    :uplinks="selection.device.uplinks"
                    :assortment="assortment"
                    :fields="fields"
                    :selection="selection"
                />
                <IpConfigurationFieldset
                    :assortment="assortment"
                    :fields="fields"
                    :selection="selection"
                />
            </Column>

            <Column>
                <ColumnTitle>{{ $t('configuratorGeneral.price') }}</ColumnTitle>
                <PriceBanner
                    :price="monthlyPrice"
                    :setup-price="setupPrice"
                />
                <Price
                    :price="monthlyPrice"
                    :setup_price="setupPrice"
                />
                <DedicatedAvailability :is-default-config="isDefaultConfig" />
                <div>
                    <hint>{{ $t('configuratorDedicated.contractTerm') }}</hint>
                    <hint>{{ $t('configuratorDedicated.noticePeriod') }}</hint>
                </div>
                <Confirm>{{ $t('configuratorGeneral.continue') }}</Confirm>
            </Column>
        </Columns>

        <div class="mx-6 mt-6">
            <BaseLine class="border-gray-500" />
        </div>

        <div class="mt-6 lg:mx-12 lg:flex">
            <div class="whitespace-nowrap text-xl text-blue-700 lg:ml-12 lg:mr-6">
                {{ $t('configuratorDedicated.crossLinkTeaser') }}
            </div>

            <div class="mx-6 text-center text-gray-300 lg:ml-6 lg:mr-12 lg:text-left">
                <span
                    v-html="
                        $t('configuratorDedicated.crossLink', { href: '/dedicated-server-mieten' })
                    "
                >
                </span>
            </div>
        </div>
    </Configurator>
</template>

<script setup>
import Configurator from './Configurator/Configurator.vue';
import Columns from './Configurator/Columns.vue';
import Column from './Configurator/Column.vue';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Radios from './Configurator/Radios.vue';
import RadioBox from './Configurator/RadioBox.vue';
import Radio from './Configurator/Radio.vue';
import CategoryTitle from './Configurator/CategoryTitle.vue';
import CheckboxQuantity from './Configurator/CheckboxQuantity.vue';
import Price from './Configurator/Price.vue';
import PriceBanner from './Configurator/PriceBanner.vue';
import Confirm from './Configurator/Confirm.vue';
import RemainingSlots from './DedicatedConfigurator/RemainingSlots.vue';
import Hint from './Configurator/Hint.vue';
import DedicatedAvailability from './DedicatedConfigurator/DedicatedAvailability.vue';
import IpConfigurationFieldset from './IpConfigurationFieldset.vue';
import NetworkConfigurationFieldset from './NetworkConfigurationFieldset.vue';
import { useAssortmentDedicated } from '../composables/assortmentDedicated';
import { removeSubstring } from '../../../utils/stringUtils';

const props = defineProps({
    assortmentContent: String,
});

const {
    fields,
    assortment,
    monthlyPrice,
    setupPrice,
    selection,
    decorateCpuSpecs,
    freeStorageSlots,
    storagesByType,
    isDefaultConfig,
} = useAssortmentDedicated(props.assortmentContent);

const increaseStorage = (id) => {
    if (freeStorageSlots < 1) {
        return;
    }
    if (fields.storageIds[id]) {
        fields.storageIds[id] += 1;
    } else {
        fields.storageIds[id] = 1;
    }
};

const decreaseStorage = (id) => {
    if (fields.storageIds[id] === undefined) {
        return;
    }
    if (fields.storageIds[id] === 1) {
        fields.storageIds[id] = 0;
    } else {
        fields.storageIds[id] -= 1;
    }
};
</script>
