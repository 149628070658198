<template>
    <div
        id="callback-form"
        class="mx-auto w-full rounded bg-gray-700 px-16 pb-14 pt-12 text-left text-white xl:w-7/12"
    >
        <div>
            <string
                v-model="form.company"
                name="company"
                :label="$t('contact.company')"
                :errors="sendingStatus.errors.company"
            />
        </div>
        <div class="flex flex-col space-x-0 md:flex-row md:space-x-6">
            <div class="flex-1">
                <string
                    v-model="form.first_name"
                    name="first_name"
                    :label="$t('contact.firstName')"
                    :errors="sendingStatus.errors.first_name"
                />
            </div>
            <div class="flex-1">
                <string
                    v-model="form.last_name"
                    name="last_name"
                    :label="$t('contact.lastName')"
                    :errors="sendingStatus.errors.last_name"
                />
            </div>
        </div>
        <div>
            <string
                v-model="form.phone"
                type="tel"
                name="phone"
                :label="$t('contact.phone')"
                :errors="sendingStatus.errors.phone"
            />
        </div>
        <div class="honeypot">
            <string
                v-model="form.mail_address"
                type="text"
                :label="$t('honeypot')"
                name="mail_address"
                tabindex="-1"
                autocomplete="off"
            />
        </div>
        <div>
            <multiline
                v-model="form.concern"
                name="message"
                :label="$t('contact.concern')"
                :errors="sendingStatus.errors.concern"
            />
        </div>
        <div class="pb-9 text-center">
            <p v-html="$t('privacy')" />
        </div>
        <div class="flex items-center justify-center space-x-5">
            <button
                class="rounded-full bg-purple-700 px-8 py-1 text-lg text-white transition hover:bg-purple-600"
                @click.prevent="send"
            >
                {{ $t('contact.send') }}
            </button>
            <p v-if="sendingStatus.busy">
                {{ $t('contact.loading') }}
            </p>
            <p
                v-if="sendingStatus.error"
                class="text-red"
                v-text="sendingStatus.error"
            />
            <p
                v-if="sendingStatus.errors.mail_address"
                class="text-red"
                v-text="sendingStatus.errors.mail_address"
            />
            <p
                v-if="sendingStatus.success"
                class="text-green"
                v-text="sendingStatus.success"
            />
        </div>
    </div>
</template>

<script>
import { UseFormSubmit } from '../functions/useFormSubmit';
import String from './Form/String.vue';
import Checkbox from './Form/Checkbox.vue';
import Multiline from './Form/Multiline.vue';

export default {
    components: { String, Checkbox, Multiline },
    setup() {
        const { form, send, sendingStatus } = UseFormSubmit(window.location.href, {
            company: '',
            first_name: '',
            last_name: '',
            phone: '',
            concern: '',
            mail_address: '',
        });

        return { form, send, sendingStatus };
    },
};
</script>
